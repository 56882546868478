.sppb-section {
    &.section-premain {
        @include sectionHeading(transparent, transparent);
        @include sectionHeadingText('', $color-accent);


        .carousel-column {

            > .sppb-column-addons {
                position: relative;
                overflow: hidden;

                &:before {
                    display: block;
                    content: '';
                    padding-bottom: 66%;
                }

                > div {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin-bottom: 0 !important;
                }

                > div:last-child {
                    bottom: auto;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    text-align: right;
                    padding: 0 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    text-shadow: #000 1px 1px 1px;
                    max-width: 555px;
                    margin-left: auto !important;
                    z-index: 1;
                    h3 {
                        font-size: inherit;
                        font-weight: 700;
                    }
                    p {
                        margin: 0;
                        line-height: normal;
                    }

                    &:before, &:after {
                        display: none;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 32px;
                        padding: 0 30px;
                    }
                }
            }
        }

        .description {
            font-size: 15px;
            padding: 15px;

            h3 {
                font-style: italic;
                font-size: 18px;
                font-weight: 300;
            }

            @include media-breakpoint-up(lg) {
                font-size: 19px;
                padding: 15px 0;

                h3 {
                    font-size: 25px;
                }
            }
        }

        .sppb-addon-text-block {
            max-width: 555px;
        }

        .carousel {
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}