.sppb-section {
    &.section-program {
        @include sectionHeading($color-main);
        @include sectionHeadingText('Program', $color-inverse);

        &::before {
            border-right-width: 2px;
        }

        @include media-breakpoint-up(lg) {
            @include sectionHeading($color-main);
        }

        text-align: left;

        .sppb-column-addons {
            & > div {
                margin-bottom: 0 !important;
            }
        }

        .sppb-section-title {
            margin-top: 50px;
        }

        .schedule-all {
            margin-left: 0;

            .decor {
                background: url('../images/speaker-bottom-bg.png') bottom left repeat-x;
                background-size: contain;
                height: 100px;
                width: 100%;
            }

            .bar {
                background-color: $color-main;
                font-size: 20px;
                font-weight: 700;
                line-height: 40px;
                text-transform: uppercase;

                .all {
                    color: $color-inverse;
                    text-decoration: none;
                    &::before {
                        background: url('../images/dots-speakers.png');
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 10px;
                        height: 29px;
                        width: 30px;
                    }

                    &::after {
                        background: url('../images/icon-arrow-right-sm.png');
                        content: '';
                        display: inline-block;
                        vertical-align: sub;
                        margin-left: 10px;
                        height: 25px;
                        width: 36px;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                text-align: right;

                .decor {
                    background-size: cover;
                    background-position: left top;
                    height: 225px;
                }

                .bar {
                    font-size: 20px;
                    line-height: 54px;

                    .all {
                        color: $color-inverse;
                        margin-right: 120px;
                        &::before {
                            background: url('../images/dots-speakers-lg.png');
                            background-size: contain;
                            height: 30px;
                            margin-right: 20px;
                            width: 33px;
                        }

                        &::after {
                            background: url('../images/icon-arrow-right-lg.png') no-repeat;
                            background-size: contain;
                            margin-left: 14px;
                            height: 22px;
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}

.sppb-addon-schedule {
    padding-top: 35px;
    padding-bottom: 35px;

    .sppb-session-break {
        font-weight: 400;
        border-top: $color-accent solid 1px;
        border-bottom: $color-accent solid 1px;
        padding: 2px 15px 2px 8px;
        margin: 10px 0 10px 10px;

        .sppb-session-break-decor {
            position: relative;
            text-align: right;

            .more {
                color: #fff;
                display: inline-block;
                background: $color-accent;
                border-radius: 360px;
                padding: 2px 10px;
                text-decoration: none;
                margin: 10px 0;
            }
        }

    }

    .sppb-schedule-time {
        font-size: 24px;
        font-weight: bold;

        &:before {
            background: $color-accent;
            border-radius: 50%;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            margin: 12px 0 0 -5px;
            height: 8px;
            width: 8px;
        }
    }

    .sppb-schedule-title {
        font-size: 16px;
        font-weight: bold;
    }

    .sppb-session-item {
        display: flex;
        flex-direction: column;

        margin-top: 30px;
        margin-left: 10px;

        &:first-child {
            margin-top: 0;
        }

        .subdescription {
            font-weight: 400;
            border-top: $color-accent solid 1px;
            border-bottom: $color-accent solid 1px;
            padding: 2px 15px 2px 8px;
            margin: 1px 0;
        }
        .session-title {
            background: $color-accent;
            color: #fff;
            display: flex;
            font-weight: bold;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            padding: 4px 15px 4px 8px;

            .time {
                flex-shrink: 0;

                &:before {
                    background: $color-accent;
                    border-radius: 50%;
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    margin: 5px 0 0 -5px;
                    height: 8px;
                    width: 8px;
                }
            }
            .title {
                max-width: 50%;
            }
        }

        .sppb-schedule-speakers {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }

        .sppb-schedule-speaker-decor {
            display: none;
        }

        .sppb-schedule-speaker {
            display: flex;
            margin-top: 15px;
            margin-right: 15px;
            width: 100%;

            .sppb-schedule-speaker-photo-ph {
                order: 1;
                display: flex;
            }

            .sppb-schedule-speaker-photo {
                background: rgba(253, 224, 58, .7);
                overflow: hidden;
                position: relative;
                flex: 0 0 auto;
                margin-right: 20px;

                height: 110px;
                width: 110px;

                img {
                    position: absolute;
                    top: -13px;
                    left: 0;
                    right: 0;
                    width: 100%;
                }
            }

            .sppb-schedule-speaker-identity {
                line-height: 2;
            }

            .sppb-schedule-speaker-name {
                text-transform: uppercase;
                position: relative;
            }

            .sppb-schedule-speaker-decor {
                position: relative;
                text-align: right;

                .more {
                    color: #fff;
                    display: inline-block;
                    background: $color-accent;
                    border-radius: 360px;
                    padding: 2px 10px;
                    text-decoration: none;
                    margin-top: 10px;
                }
            }

            &.clickable {
                cursor: pointer;

                .sppb-schedule-speaker-name {
                    display: inline-block;

                    &:before {
                        background: rgba(253, 224, 58, .7);
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: -5px;
                        bottom: 0;
                        width: 20px;
                        z-index: -1;
                        transition: width 100ms ease;
                    }
                }

                &:hover {
                    .sppb-schedule-speaker-name {


                        &:before {
                            width: calc(100% + 10px);
                        }
                    }
                }
            }
        }
    }

    .download-pdf {
        margin-top: 30px;
        text-align: center;

        .button {
            background: $color-accent;
            border-radius: 360px;
            color: $color-main;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            padding: 6px 20px;
            text-decoration: none;
            text-transform: uppercase;
        }
    }

    @include media-breakpoint-up(lg) {
        $padding: 10%;

        .margin {
            height: 63px;
        }

        .sppb-session-break {
            margin-left: 0;
            padding-left: 25px !important;
            padding-right: 25px !important;

            .inner {
                padding-left: calc(#{$padding} / 2 - 25px);
                padding-right: calc(#{$padding} / 2 - 25px);
            }
        }

        .sppb-addon-content {
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;

            .sppb-session-break {
                margin-left: 200px;
            }

            .sppb-schedule-time {
                font-size: 1.8rem;
                margin-right: 20px;
                text-align: center;
                flex: 0 0 200px;
            }
            .sppb-schedule-title {
                font-size: 1.8rem;
            }
            .sppb-schedule-info {
                flex-grow: 1;
            }

            .sppb-schedule-speaker-moderator {
                color: $color-accent;
                margin-right: 20px;
                font-weight: 400;
                text-transform: uppercase;
            }

            .sppb-session-item {
                flex-direction: row;

                .subdescription {
                    padding-right: $padding;
                }
                .session-title {
                    background: #a067ee;
                    background: linear-gradient(to right, #a067ee 0%,$color-accent 100%);
                    padding-right: $padding;
                }
                .sppb-schedule-time {
                    &:before {
                        display: none;
                    }
                }
                .sppb-schedule-speaker {
                    width: 100%;

                    &.moderator {
                        flex-wrap: nowrap;
                        align-items: center;
                        margin-left: calc((50% + 15px) * 2);
                        position: relative;
                        overflow: visible;

                        .sppb-schedule-speaker-photo-ph {
                            flex: 1 1 50%;
                        }

                        .sppb-schedule-speaker-moderator {
                            position: absolute;
                            right: 100%;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .sppb-addon-content {
            overflow: hidden;
        }
        .sppb-session-item {
            margin-top: 35px;
            margin-left: 0;

            .session-title {
                font-size: 18px;

                .title {
                    max-width: 80%;
                }
            }

            .sppb-schedule-speaker {
                .sppb-schedule-speaker-photo {
                    height: 100px;
                    width: 100px;
                }
                .sppb-schedule-speaker-time {
                    font-size: 18px;
                }
                .sppb-schedule-speaker-name {
                    font-size: 18px;
                }
                .sppb-schedule-speaker-designation {
                    font-size: 14px;
                }
                .sppb-schedule-speaker-company {
                    font-size: 14px;
                }
                .sppb-schedule-speaker-intro {
                    font-size: 16px;
                }
            }

            .time:before, .sppb-schedule-speaker-time:before {
                left: 50% !important;
                height: 16px !important;
                width: 16px !important;
                margin: 6px 0 0 -8px !important;
            }
        }
    }

    @media all and (min-width: 1800px) {
        $padding: 40%;

        .sppb-session-break {
            .inner {
                padding-left: calc(#{$padding} / 2 - 25px);
                padding-right: calc(#{$padding} / 2 - 25px);
            }
        }
    }
}

.sppb-schedule-popup {
    background-color: $color-accent;
    color: $color-main;
    display: none;
    opacity: 0.95;
    margin-left: $decor-column-max-width;
    padding: 25px 17px 20px;
    text-align: left;
    position: relative;

    @include media-breakpoint-up(lg) {
        margin-left: $decor-column-max-width-lg;
        padding: 70px 17px 50px;
    }

    .popup-close {
        background: url('../images/ico-close.png') no-repeat center;
        background-size: 18px;
        cursor: pointer;
        height: 38px;
        width: 38px;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 110;

        @include media-breakpoint-up(lg) {
            background-size: auto;
            right: 30px;
            top: 30px;
        }
    }

    @include media-breakpoint-up(lg) {
        .sppb-speaker-popup-inner {
            margin-left: auto;
            margin-right: auto;
            max-width: 900px;
        }
    }
}
.popup-overlay .sppb-schedule-popup {
    display: block;
}

.sppb-addon-schedule-prev-popup {
    background-color: rgba(#1a2552, .95);
    color: $color-inverse;
    display: none;
    opacity: 0.95;
    text-align: left;
    padding-top: 65px;
    position: relative;
    margin-left: $decor-column-max-width;

    @include media-breakpoint-up(lg) {
        padding: 70px 0 50px;
        margin-left: $decor-column-max-width-lg;
    }

    .sppb-addon-speakers-prev {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .schedule-prev-close {
        background: url('../images/ico-close.png') no-repeat center;
        background-size: 18px;
        cursor: pointer;
        height: 38px;
        width: 38px;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 110;

        @include media-breakpoint-up(lg) {
            background-size: auto;
            right: 30px;
            top: 30px;
        }
    }

    .sppb-addon-speakers-prev {
        margin-left: $decor-column-max-width;
        &.desktop {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            margin-left: $decor-column-max-width-lg;

            &.desktop {
                display: block;
                margin: 0 160px;
            }
            &.mobile {
                display: none;
            }
        }
        .speakers-prev {
            display: flex;
        }
    }
}
.popup-overlay .sppb-addon-schedule-prev-popup {
    display: block;
}