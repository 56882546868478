.sppb-section {
    &.section-reviews {
        @include sectionHeading($color-main, #2e3a6a);
        @include sectionHeadingText('Reviews', $color-inverse);

        background: #fff url('../images/speaker-bottom-bg.png') bottom left repeat-x;
        background-size: contain;

        .sppb-container-inner {
            @extend .ph;
        }

        .sppb-addon-reviews {
            .review-item {
                text-align: left;
                background: #f5f5f5;
            }

            .review-text {
                font-weight: bold;
                padding: 14px;
            }

            .review-author {
                margin-top: 10px;
                padding: 14px;
            }

            .sppb-embed-responsive {
                padding-top: 56.25%;
            }
        }
    }
}