.sppb-section {
    &.section-partners {
        @include sectionHeading($color-main);
        @include sectionHeadingText('Partners', $color-main);

        background: $color-inverse;
        box-shadow:
                inset 0px 20px 20px -20px #444,
                inset 0px -20px 20px -20px #444;
        padding: 60px 0;

        &:before {
            background: $color-inverse;
            box-shadow:
                    inset 0px 20px 20px -20px #444,
                    inset 0px -20px 20px -20px #444;
        }

        & > .sppb-container-inner {
            @extend .ph;
        }

        .sppb-column-addons {
            & > div {
                margin-bottom: 0 !important;
            }
            .sppb-column-addons {
                @include media-breakpoint-up(lg) {
                    display: flex;
                    justify-content: center;

                    & > div {
                        flex-shrink: 0;
                        margin-left: 55px !important;
                        margin-right: 55px !important;
                    }
                }
            }
        }

        .sppb-addon-partners {
            &.desktop {
                display: none;
            }
            .partners {
                border-bottom: $color-accent 1px solid;
            }
            .partners, .media-partners {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .logo {
                margin: 25px 0;
                text-align: center;
                width: 50%;

                img {
                    display: inline-block;
                    max-height: 60px;
                    max-width: 90px;
                    width: auto;
                }

                @include media-breakpoint-up(lg) {
                    width: 25%;
                }
            }

            @include media-breakpoint-up(lg) {
                &.desktop {
                    display: block;

                    .logo {
                        img {
                            max-height: 90px;
                            max-width: 130px;
                        }
                    }
                }
                &.mobile {
                    display: none;
                }
            }
        }

        .sppb-became-partner {
            margin-top: 40px;
            text-align: center;

            a {
                color: $color-main;
                display: inline-block;
                font-size: 18px;
                font-weight: 900;
                text-transform: uppercase;
                text-decoration: none;

                span {
                    margin-left: 35px;
                    border-bottom: $color-main solid 2px;
                }
/*                &::before {
                    display: inline-block;
                    content: '';
                    background: url('../images/icon-became-partner.png') no-repeat;
                    background-size: cover;
                    margin: 4px 0 -35px;
                    position: absolute;
                    height: 17px;
                    width: 25px;
                }*/
            }

            @include media-breakpoint-up(lg) {
                text-align: right;

                a {
                    font-size: 24px;

                    &::before {
                        height: 28px;
                        margin: 5px 0 0 -20px;
                        width: 40px;
                    }
                }
            }
        }
    }
}
.sppb-partners-popup {
    background-color: $color-accent;
    color: $color-main;
    display: none;
    font-size: 14px;
    font-weight: 400;
    margin-left: $decor-column-max-width;
    padding: 55px 17px 20px;
    text-align: left;
    position: relative;

    @include media-breakpoint-up(lg) {
        margin-left: 0;
        padding: 70px 50px 40px;
        max-width: 770px;
        left: 50% !important;
        transform: translateX(-50%);
    }

    span {
        color: $color-main;
        font-weight: bold;
    }

    .became-close {
        background: url('../images/ico-close.png') no-repeat center;
        background-size: contain;
        cursor: pointer;
        height: 35px;
        width: 35px;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 110;

        @include media-breakpoint-up(lg) {
            background-size: contain;
            right: 42px;
            top: 42px;
            height: 25px;
            width: 25px;
        }
    }

    @include media-breakpoint-up(lg) {
        .sppb-partners-popup-inner {
            margin-left: auto;
            margin-right: auto;
            max-width: map_get($container-max-widths, lg);
        }
    }

    .sppb-partners-popup-form {
        margin-top: 30px;
    }

    .field {
        margin: 0 5% !important;
        position: relative;

        @include media-breakpoint-up(lg) {
            margin: 0 30% !important;
        }

        input[type=text] {
            &::-webkit-input-placeholder {color:$color-inverse;}
            &::-moz-placeholder          {color:$color-inverse;}/* Firefox 19+ */
            &:-moz-placeholder           {color:$color-inverse;}/* Firefox 18- */
            &:-ms-input-placeholder      {color:$color-inverse;}

            border: 0;
            border-color: transparent !important;
            border-bottom: 1px solid $color-main !important;
            border-radius: 0 !important;
            background-color: transparent !important;
            color: $color-main !important;
            padding-left: 5px;
            padding-right: 5px;
        }
        label {
            color: $color-main !important;
            @include media-breakpoint-down(md) {
                font-size: 18px !important;
            }

            &::after {
                color: $color-main !important;
            }
        }
        .prompt.label {
            position: absolute;
            left: 6px;
            top: 70px;
            z-index: 1;
        }

        &.error {
            input[type=text] {
                border-bottom: 1px solid #ff0000 !important;
                border-radius: 0 !important;
            }
        }
    }

    .button-send {
        background-color: $color-inverse !important;
        border-radius: 360px !important;
        color: $color-main !important;
        margin-top: 20px !important;
        margin-right: 0 !important;
        text-transform: uppercase !important;
        width: 90%;

        @include media-breakpoint-up(lg) {
            width: 40% !important;
        }
    }

    .ui.message.success {
        color: $color-inverse !important;
    }
}
.popup-overlay .sppb-partners-popup {
    display: block;
}