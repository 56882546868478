.bg-line {
    padding: 0 !important;
    overflow: visible;

    .sppb-row-container {
        display: none;
    }

    &:before {
        background-size: contain;
        display: block;
        content: '';
        position: absolute;
        z-index: 0;
        width: 100%;
    }

    &-1 {
        &:before {
            background-image: url("../images/bg-line-1-mob.png");
            padding-bottom: 73.69%;

            @include media-breakpoint-up(lg) {
                background-image: url("../images/bg-line-1.png");
            }
        }
    }

    &-2 {
        &:before {
            background-image: url("../images/bg-line-2-mob.png");
            padding-bottom: 88.9%;

            @include media-breakpoint-up(lg) {
                background-image: url("../images/bg-line-2.png");
            }
        }
    }

    &-3 {
        &:before {
            background-image: url("../images/bg-line-3-mob.png");
            padding-bottom: 111.51%;

            @include media-breakpoint-up(lg) {
                background-image: url("../images/bg-line-3.png");
            }
        }
    }

    &-4 {
        &:before {
            background-image: url("../images/bg-line-4-mob.png");
            padding-bottom: 50.1%;

            @include media-breakpoint-up(lg) {
                background-image: url("../images/bg-line-4.png");
            }
        }
    }
}