@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
    url('../webfonts/subset-Montserrat-BlackItalic.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-BlackItalic.woff') format('woff'),
    url('../webfonts/subset-Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../webfonts/subset-Montserrat-Bold.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-Bold.woff') format('woff'),
    url('../webfonts/subset-Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
    url('../webfonts/subset-Montserrat-BoldItalic.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-BoldItalic.woff') format('woff'),
    url('../webfonts/subset-Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black'), local('Montserrat-Black'),
    url('../webfonts/subset-Montserrat-Black.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-Black.woff') format('woff'),
    url('../webfonts/subset-Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
    url('../webfonts/subset-Montserrat-ExtraBoldItalic.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-ExtraBoldItalic.woff') format('woff'),
    url('../webfonts/subset-Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('../webfonts/subset-Montserrat-ExtraBold.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-ExtraBold.woff') format('woff'),
    url('../webfonts/subset-Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url('../webfonts/subset-Montserrat-ExtraLight.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-ExtraLight.woff') format('woff'),
    url('../webfonts/subset-Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
    url('../webfonts/subset-Montserrat-ExtraLightItalic.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-ExtraLightItalic.woff') format('woff'),
    url('../webfonts/subset-Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url('../webfonts/subset-Montserrat-Italic.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-Italic.woff') format('woff'),
    url('../webfonts/subset-Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light'), local('Montserrat-Light'),
    url('../webfonts/subset-Montserrat-Light.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-Light.woff') format('woff'),
    url('../webfonts/subset-Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url('../webfonts/subset-Montserrat-LightItalic.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-LightItalic.woff') format('woff'),
    url('../webfonts/subset-Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
    url('../webfonts/subset-Montserrat-MediumItalic.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-MediumItalic.woff') format('woff'),
    url('../webfonts/subset-Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../webfonts/subset-Montserrat-Medium.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-Medium.woff') format('woff'),
    url('../webfonts/subset-Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../webfonts/subset-Montserrat-Regular.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-Regular.woff') format('woff'),
    url('../webfonts/subset-Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('../webfonts/subset-Montserrat-SemiBold.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-SemiBold.woff') format('woff'),
    url('../webfonts/subset-Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
    url('../webfonts/subset-Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-SemiBoldItalic.woff') format('woff'),
    url('../webfonts/subset-Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
    url('../webfonts/subset-Montserrat-Thin.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-Thin.woff') format('woff'),
    url('../webfonts/subset-Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
    url('../webfonts/subset-Montserrat-ThinItalic.woff2') format('woff2'),
    url('../webfonts/subset-Montserrat-ThinItalic.woff') format('woff'),
    url('../webfonts/subset-Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

