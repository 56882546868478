.sppb-section {
    &.section-networking {
        @include sectionHeading($color-main);
        @include sectionHeadingText('Speed Networking', $color-inverse);

        text-align: center;
        min-height: 600px;

        .sppb-container-inner {
            @extend .ph;
        }

        .sppb-addon-text-block {
            h3 {
                font-size: 3rem;
                font-weight: bold;
                text-transform: uppercase;
            }

            strong {
                font-size: 1.8rem;
                font-weight: bold;
                text-transform: uppercase;
            }

            text-align: left;
            line-height: 2;
        }

        .sppb-img-responsive {
            box-shadow: #000 0 0 40px;
        }
    }
}