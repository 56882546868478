.sppb-section {
    &.section-intro {
        @include sectionHeading($color-main);

        padding-top: 50px;
        position: relative;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }

        .sppb-container-inner {
            height: 100%;

            .sppb-row {
                height: 100%;

                .sppb-column-addons {
                    display: flex;
                }
            }
        }

        .sppb-intro-desktop {
            display: none;
        }

        .sppb-intro-image {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            .sppb-intro-mobile {
                display: none;
            }

            .sppb-intro-desktop {
                display: block;
                margin-top: 80px;
            }
        }
    }

    &.section-intro, &.section-intro-2 {
        .questions {
            .sppb-column-addons {
                align-self: flex-end;
            }

            strong {
                text-transform: uppercase;
                font-weight: bold;

                span {
                    background: $color-accent;
                    color: $color-main;
                    padding: 0 .3em;
                }
            }
        }
    }

    &.section-intro {
        h2 {
            font-size: 17px;
        }
        .questions {
            .sppb-column-addons {
                height: 70%;

                .sppb-addon-text-block {
                    height: 100%;

                    div {
                        height: 100%;
                    }

                    ul {
                        height: 100%;
                    }
                }

                &::before {
                    background: $color-accent;
                    bottom: 0;
                    content: '';
                    display: block;
                    position: absolute;
                    top: 15px;
                    width: 1px;
                    z-index: 0;
                }
            }

            ul {
                &::before {
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 15%,rgba(255,255,255,1) 85%,rgba(255,255,255,0) 100%);
                    content: '';
                    display: block;
                    top: -80px;
                    left: 20px;
                    right: 0;
                    bottom: -80px;
                    position: absolute;
                    z-index: 0;
                }
            }
            li {
                padding-left: 35px;
                padding-right: 25px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &.section-intro-2 {
        overflow: hidden;
        padding-bottom: 4rem;
        padding-top: 4rem;

        &::before {
            @include sectionHeading($color-inverse);
        }
        &::after {
            @include sectionHeadingText('');
        }

        @include sectionHeading($color-main);

        & > .sppb-container-inner {
            @extend .ph;
        }

        .questions {
            font-size: 1.2rem;
            width: auto;

            strong {
                font-size: 1.4rem;
            }
        }

        .description {
            margin-top: 2rem;
        }

        @include media-breakpoint-up(lg) {
            background: none;

            .description {
                width: 80%;
            }

            &::before {
                background: transparent;
            }
        }
    }
}
