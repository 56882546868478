// TEMPLATE STYLES

// bootstrap overrides only (taken from variables.scss)
// hint: write values without !default

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    hd: 1600px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1500px
);

$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$color-accent: #fde03a;
$color-main: #353535;
$color-inverse: #ffffff;
$color-hover: #0000ff;

$decor-column-max-width: 40px;
$decor-column-max-width-lg: 80px;

$font-size-default: 11px;
$font-size-default-lg: 14px;
$font-size-default-hd: 17px;