html, body {
    font-size: $font-size-default;

    @include media-breakpoint-up(lg) {
        font-size: $font-size-default-lg;
    }

    @include media-breakpoint-up(hd) {
        font-size: $font-size-default-hd;
    }
}
body {
    background-image: url("../images/page-bg.jpg");
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    background-color: #353535;
    font-weight: 300;
    color: $color-inverse;
}

#print {
    background: #fff;
}

.ph {
    flex-grow: 1;
    margin-left: 20px;
    margin-right: 20px;

    @include media-breakpoint-up(lg) {
        max-width: map_get($container-max-widths, lg);
    }

    @include media-breakpoint-up(xl) {
        max-width: map_get($container-max-widths, xl);
    }

    @include media-breakpoint-up(hd) {
        margin-left: auto;
        margin-right: auto;
    }
}

.ph-plain {
    @include media-breakpoint-up(lg) {
        margin-left: auto;
        margin-right: auto;
        max-width: map_get($container-max-widths, lg);
    }

    @include media-breakpoint-up(xl) {
        max-width: map_get($container-max-widths, xl);
    }
}
.btn-dark {
    background: $color-main;
    border-radius: 360px;
    color: $color-inverse;
}
.btn-light {
    background: $color-inverse;
    border-radius: 360px;
    color: $color-main;
    font-size: 24px;
}
.btn-ticket {
    background: $color-inverse;
    border-radius: 360px;
    color: $color-main;
    text-transform: uppercase;
    padding: 0;
    font-size: 18px;
    box-shadow: none !important;
    outline: 0;

    @include media-breakpoint-up(lg) {
        font-size: 14px;
    }
}

.owl-theme .owl-dots .owl-dot {
    span {
        background-color: $color-accent;
        opacity: .7;
    }
    &.active {
        span {
            background-color: $color-accent;
            opacity: 1;
        }
    }
}

.sppb-section {
    overflow: hidden;

    .sppb-container-inner {
        overflow: hidden;
    }

    .sppb-section-title {
        margin-bottom: 20px !important;

        .sppb-title-heading {
            font-size: 20px !important;
        }
    }
    @include media-breakpoint-up(lg) {
        .sppb-section-title {
            margin-bottom: 30px !important;

            .sppb-title-heading {
                font-size: 36px !important;
            }
        }
    }
}

.sp-page-builder {
    & > .page-content {
        & > .sppb-section {
            & > .sppb-container-inner {
                padding-left: $decor-column-max-width;

                @include media-breakpoint-up(lg) {
                    padding-left: $decor-column-max-width-lg;
                }
            }
        }
    }
}

.section-margin {
    background: url('../images/section-margin.png') center;
    background-size: cover;
    height: 86px;

    @include media-breakpoint-up(lg) {
        height: 155px;
    }
}

.popup-opened {
    overflow: hidden;
}
.popup-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow-y: scroll;

    top: 50px;

    &.dark {
        background-color: rgba(0, 0, 0, .8);
    }

    @include media-breakpoint-up(lg) {
        top: 123px;
    }

    .pop-up {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
}
.ui.modal .scrolling.content {
    max-height: none !important;
    padding: 0 !important;
}

.accent {
    color: $color-accent;
}