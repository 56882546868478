.sppb-section {
    &.section-speakers {
        @include sectionHeading($color-main);
        @include sectionHeadingText('Speakers', $color-inverse);

        text-align: center;

        .sppb-column-addons {
            & > div {
                margin-bottom: 0 !important;
            }
        }
        .sppb-section-title {
            margin-top: 50px;
        }
        .sppb-addon-speakers {
            .sppb-addon-content {
                @extend .ph-plain;

                flex-direction: column;
                align-items: center;

                @include media-breakpoint-up(lg) {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: space-between;
                    max-width: 1200px;
                }

                .sppb-speaker-item {
                    flex: 1 0 100%;
                    margin: 30px auto;
                    text-align: left;

                    &:nth-child(n+4) {
                        display: none;
                    }

                    .sppb-speaker-inner {
                        cursor: pointer;
                        width: 180px;
                        margin: auto;
                    }

                    &.merged {
                        display: flex !important;
                        flex: 1 0 50%;
                        flex-wrap: wrap;
                        position: relative;

                        .sppb-speaker-inner {
                            width: 80%;

                            & > .sppb-speaker-item-merged-col {
                                display: flex;
                                flex-direction: row;
                            }
                        }

                        .sppb-speaker-item-photo {
                            padding-bottom: 50%;

                            img {
                                height: 100%;
                                width: 50%;

                                &:last-child {
                                    left: 50%;
                                }
                            }
                        }

                        .sppb-speaker-item-merged-col {
                            .sppb-speaker-item-name, img {
                                width: 50%;
                            }

                            @include media-breakpoint-up(lg) {
                                display: flex;
                                flex-direction: row;
                            }
                        }
                    }

                    .sppb-speaker-item-slogan {
                        font-weight: bold;
                        color: $color-accent;
                        text-transform: uppercase;
                    }

                    .sppb-speaker-item-photo {
                        position: relative;
                        text-align: center;
                        padding-bottom: 100%;

                        img {
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            object-fit: contain;
                            z-index: 1;
                        }
                    }

                    .sppb-speaker-item-name {
                        font-size: 1.5rem;
                        font-weight: 700;
                        line-height: 1.2;
                        margin-top: 14px;
                        position: relative;
                        text-transform: uppercase;
                    }


                    .sppb-speaker-item-company {
                        font-weight: 400;
                        font-size: 0.9rem;
                        margin-top: 5px;
                    }

                    @include media-breakpoint-up(lg) {
                        flex: 1 0 33%;

                        &.merged {
                            flex: 1 0 66%;
                            display: flex !important;

                            .sppb-speaker-inner {
                                width: 80%;
                                max-width: 600px;
                            }
                        }

                        &:nth-child(n+4) {
                            display: block;
                        }

                        .sppb-speaker-inner {
                            width: 80%;
                            max-width: 300px;
                        }

                        .sppb-speaker-item-name {
                            margin-top: 30px;
                        }

                        .sppb-speaker-item-company {
                            min-height: 48px;
                            max-width: 80%;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .more {
                background: url("../images/icon-arrow-double.png");
                display: inline-block;
                height: 30px;
                width: 30px;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            &.view-more {
                .sppb-addon-content {
                    .sppb-speaker-item {
                        &:nth-child(n+4) {
                            display: block;
                        }
                    }
                }

                .more {
                    display: none;
                }
            }
        }

        .speakers-all {
            margin-left: 0;

            .decor {
                background: url('../images/speaker-bottom-bg.png') bottom left repeat-x;
                background-size: contain;
                height: 100px;
                width: 100%;
            }

            .bar {
                background-color: $color-main;
                font-size: 20px;
                font-weight: 700;
                line-height: 40px;
                text-transform: uppercase;

                .all {
                    color: $color-inverse;
                    text-decoration: none;
                    &::before {
                        background: url('../images/dots-speakers.png');
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 10px;
                        height: 29px;
                        width: 30px;
                    }

                    &::after {
                        background: url('../images/icon-arrow-right-sm.png');
                        content: '';
                        display: inline-block;
                        vertical-align: sub;
                        margin-left: 10px;
                        height: 25px;
                        width: 36px;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                text-align: right;

                .decor {
                    background-size: cover;
                    background-position: left top;
                    height: 225px;
                }

                .bar {
                    font-size: 20px;
                    line-height: 54px;

                    .all {
                        color: $color-inverse;
                        margin-right: 120px;
                        &::before {
                            background: url('../images/dots-speakers-lg.png');
                            background-size: contain;
                            height: 30px;
                            margin-right: 20px;
                            width: 33px;
                        }

                        &::after {
                            background: url('../images/icon-arrow-right-lg.png') no-repeat;
                            background-size: contain;
                            margin-left: 14px;
                            height: 22px;
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}

.sppb-speaker-popup {
    background-color: $color-accent;
    color: $color-main;
    display: none;
    margin-left: $decor-column-max-width;
    text-align: left;
    position: relative;

    @include media-breakpoint-up(lg) {
        margin-left: $decor-column-max-width-lg;
    }

    .popup-close {
        background: url('../images/ico-close.png') no-repeat center;
        background-size: 18px;
        cursor: pointer;
        height: 38px;
        width: 38px;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 110;

        @include media-breakpoint-up(lg) {
            background-size: auto;
            right: 30px;
            top: 30px;
        }
    }

    @include media-breakpoint-up(lg) {
        .sppb-speaker-popup-inner {
            display: flex;
        }
    }

    .sppb-speaker-popup-col1, .sppb-speaker-popup-col2 {
        padding: 25px 17px 20px;

        @include media-breakpoint-up(lg) {
            padding: 70px 17px 50px;
        }
    }

    .sppb-speaker-popup-col1 {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fde661;
        flex-shrink: 0;

        @include media-breakpoint-up(lg) {
            width: 30%;
        }
    }

    .sppb-speaker-popup-col2 {
        @include media-breakpoint-up(lg) {
            padding: 70px;
        }
    }

    .sppb-speaker-popup-person {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            justify-content: space-between;
        }

        .sppb-speaker-popup-image {
            position: relative;
            margin: auto;
            width: 80%;

            @include media-breakpoint-up(lg) {
                width: 80%;
            }

            .sppb-speaker-popup-photo {
                position: relative;
                overflow: hidden;
                width: 70%;
                margin: auto;

                img {
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                    z-index: 1;
                    width: 100%;
                }
            }

            .sppb-speaker-popup-name {
                background: $color-main;
                color: $color-inverse;
                font-size: 2rem;
                font-weight: bold;
                letter-spacing: 1.8px;
                margin-top: 1rem;
                margin-bottom: .5rem;
                padding: .3rem 1rem;
                text-transform: uppercase;
                overflow-wrap: break-word;

                @include media-breakpoint-up(lg) {
                    font-size: 1.5em;
                }
            }
        }
    }

    .social {
        display: inline-block;
        background-size: cover !important;
        margin-top: 20px;
        margin-right: 20px;
        height: 35px;
        width: 35px;

        @media (max-width: 320px) {
            height: 30px;
            width: 30px;
        }

        &-in {
            background: url("../images/ico-in-dark.png") bottom no-repeat;
        }
        &-fb {
            background: url("../images/ico-fb-dark.png") bottom no-repeat;
        }

        &:hover {
            background-position: top;
        }
    }

    .sppb-speaker-popup-sessions {
        margin-top: 5px;

        .sppb-speaker-popup-session {
            background: $color-inverse;
            color: $color-main;
            margin: 5px 0 25px;
            font-size: 12px;
            font-weight: 900;
            padding: 10px;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                padding: 20px 30px;
            }
        }

        .sppb-speaker-popup-session-time {
            font-size: 13px;

            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }
        }

        .sppb-speaker-popup-session-title {
            min-height: 30px;
            margin-top: 5px;
            text-transform: uppercase;
        }
    }

    .sppb-speaker-popup-designation {
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    .sppb-speaker-popup-merge-toggle {
        margin-top: 20px;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }
    }
    .sppb-speaker-popup-merge-toggle-link {
        color: $color-inverse;
        text-decoration: underline;
    }

    &.merged {
        .merge-last {
            display: none;
        }

        .sppb-speaker-popup-person {
            .sppb-speaker-popup-image {
                .sppb-speaker-popup-photo {
                    cursor: pointer;

                    &.mini {
                        height: 80px;
                        width: 80px;
                        position: absolute;
                        top: 0;
                        left: 70%;

                        @include media-breakpoint-up(lg) {
                            height: 100px;
                            width: 100px;
                            left: 150px;
                        }
                    }
                }
            }
        }

        &.show-last {
            .merge-first {
                display: none;
            }

            .merge-last {
                display: block;
            }

            .sppb-speaker-popup-person.merge-last {
                display: flex;
            }
        }
    }
}
.popup-overlay .sppb-speaker-popup {
    display: block;
}

.sppb-addon-speakers-prev-popup {
    background-color: rgba(#1a2552, .95);
    color: $color-inverse;
    display: none;
    opacity: 0.95;
    text-align: left;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding: 70px 0 50px;
        margin-left: $decor-column-max-width-lg;
    }

    .sppb-adddon-speakers-title {
        border-right: $color-accent 1px solid;
        position: absolute;
        height: 100%;
        width: $decor-column-max-width;

        @include media-breakpoint-up(lg) {
            border-right: 0;
            width: 160px;
        }

        span {
            color: $color-accent;
            font-size: 30px;
            font-weight: bold;
            position: absolute;
            padding-right: 60px;
            text-transform: uppercase;
            transform: rotate(-90deg) translateX(-100%);
            transform-origin: 0 0;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
                font-size: 40px;
                line-height: 160px;
            }
        }
    }

    .owl-item {
        padding: 0 15px;
    }
    .sppb-addon-speakers-prev {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .speakers-prev {
        display: flex;
        justify-content: space-between;
        position: relative;

        &:first-child {
            padding-bottom: 10px;

            @include media-breakpoint-up(lg) {
                padding-bottom: 24px;
            }
        }

        &:nth-child(2) {
            border-top: $color-accent solid 1px;
            padding-top: 23px;
            &:after {
                border-top: $color-accent solid 1px;
                border-right: $color-accent solid 1px;
                content: '';
                display: block;
                height: 10px;
                width: 10px;
                position: absolute;
                right: 0;
                top: 0;
                transform: rotate(45deg);
                transform-origin: 100% 0;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 50px;
                &:after {
                    border-top: $color-accent solid 2px;
                    border-right: $color-accent solid 2px;
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
    .sppb-speaker-prev-inner {
        width: 45%;

        @include media-breakpoint-up(lg) {
            width: 20%;
        }
        .sppb-speaker-prev-item-photo {
            background: #9b9b9b;
            border: $color-accent 3px solid;
            border-radius: 3px;
            width: 100%;
        }
        .sppb-speaker-prev-item-name {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            margin: 5px 0;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                margin: 20px 0 10px;
            }
        }
        .sppb-speaker-prev-item-designation {
            font-size: 10px;

            @include media-breakpoint-up(lg) {
                font-size: 14px;
            }
        }
    }
    .speakers-prev-close {
        background: url('../images/ico-close.png') no-repeat center;
        background-size: 18px;
        cursor: pointer;
        height: 38px;
        width: 38px;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 110;

        @include media-breakpoint-up(lg) {
            background-size: auto;
            right: 30px;
            top: 30px;
        }
    }
    .sppb-addon-speakers-prev {
        margin-left: $decor-column-max-width;
        &.desktop {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            margin-left: $decor-column-max-width-lg;

            &.desktop {
                display: block;
                margin: 0 160px;
            }
            &.mobile {
                display: none;
            }
        }
        .speakers-prev {
            display: flex;
        }
    }

    .owl-dot {
        span {
            background: #fff !important;
        }
        &.active {
            span {
                background: $color-accent!important;
            }
        }
    }
}
.popup-overlay .sppb-addon-speakers-prev-popup {
    display: block;
}