.en-GB {
    .ticket-form {
        .multifield.payment {
            .field:last-child {
                display: none;
            }
        }
    }
}

.ru-RU {
    .section-main {
        &::after {
            content: 'Информация' !important;
        }
    }
    .section-speakers {
        &::after {
            content: 'Спикеры' !important;
        }
    }
    .section-tickets {
        &::after {
            content: 'Билеты' !important;
        }
    }
    .section-partners {
        &::after {
            content: 'Партнеры' !important;
        }
    }
    .section-contacts {
        &::after {
            content: 'Контакты' !important;
        }
    }
    .section-program {
        &::after {
            content: 'Программа' !important;
        }
    }
    .section-reviews {
        &::after {
            content: 'Отзывы' !important;
        }
    }
}

.uk-UA {
    .section-main {
        &::after {
            content: 'Інформація' !important;
        }
    }
    .section-speakers {
        &::after {
            content: 'Спікери' !important;
        }
    }
    .section-tickets {
        &::after {
            content: 'Квитки' !important;
        }
    }
    .section-partners {
        &::after {
            content: 'Партнери' !important;
        }
    }
    .section-contacts {
        &::after {
            content: 'Контакти' !important;
        }
    }
    .section-program {
        &::after {
            content: 'Програма' !important;
        }
    }
    .section-reviews {
        &::after {
            content: 'Відгуки' !important;
        }
    }

    @include media-breakpoint-up(lg) {
        .navbar-header .navbar-ticket .btn-ticket {
            font-size: 14px;
        }
    }
}