@mixin fontSizeRem($font-size, $important: false) {
    @if ($important == true) {
        font-size: $font-size !important;
        font-size: ($font-size / $font-size-default) + rem !important;
    } @else {
        font-size: $font-size;
        font-size: ($font-size / $font-size-default) + rem;
    }
}

@mixin sectionHeading($color1: '', $color2: '', $bg: '') {
    &::before {
        border-right: $color-accent solid 1px;
        content: '';
        @if ($bg == '') {
            @if ($color1 != '' and $color2 != '') {
                background: linear-gradient(to top, $color1 0%, $color2 100%);
            } @else {
                background: $color1;
            }
        } @else {
            @if ($color1 != '' and $color2 != '') {
                background: $bg, linear-gradient(to top, $color1 0%, $color2 100%);
            } @else {
                background: $bg, $color1;
            }
        }

        @if ($color1 == $color-main) {
            color: $color-inverse;
        }

        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: $decor-column-max-width;
        z-index: 0;

        @include media-breakpoint-up(lg) {
            width: $decor-column-max-width-lg;
        }
    }
}
@mixin sectionHeadingText($text, $color: $color-inverse) {
    position: relative;

    &::after {
        content: $text;
        color: $color;
        display: block;
        position: absolute;
        letter-spacing: 6px;
        left: -2px;
        top: 60px;
        text-transform: uppercase;
        transform: rotate(-90deg) translateX(-100%);
        transform-origin: 0 0;
        white-space: nowrap;

        font-size: 30px;
        font-weight: 700;

        @include media-breakpoint-up(lg) {
            font-size: 44px;
            left: 5px;
            transform: rotate(-90deg) translateX(-50%);
            top: 50%;
        }
    }
}