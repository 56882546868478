.btn-fly-ticket {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 120px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    transition: all ease-in-out 200ms;
    z-index: 5;
    background: #fde03a url(../images/ico-ticket.svg) center no-repeat;
    background-size: 70%;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}