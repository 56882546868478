.navbar-header {
    background: $color-main;
    color: $color-inverse;
    flex-wrap: nowrap;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;

    p {
        margin: 0;
    }

    .navbar-toggler {
        background: $color-accent;
        border-radius: 0;
        color: $color-main;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        align-self: stretch;
        padding: 20px 10px 19px;
        margin: 0;
        min-height: 50px;
        max-width: $decor-column-max-width;
        width: 100%;

        @include media-breakpoint-up(lg) {
            min-height: 80px;
            max-width: $decor-column-max-width-lg;
            padding: 30px 15px 29px;
        }

        span {
            background: $color-main;
            display: block;
            height: 1px;
            width: 100%;

            @include media-breakpoint-up(lg) {
                height: 3px;
            }
            &:last-child {
                width: 70%;
            }
        }
    }

    .ph {
        align-self: stretch;
        display: flex;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
    }

    .navbar-brand {
        margin-left: 20px;

        @include media-breakpoint-up(lg) {
            img {
                height: 65px;
            }
        }
    }

    .navbar-info {
        flex-grow: 1;
        font-size: 10px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;

        .phone {
            color: inherit;
        }

        @include media-breakpoint-up(lg) {
            font-size: 28px;

            .sub {
                font-size: 20px;
            }

            .phone {
                display: none;
            }
        }
    }

    .navbar-ticket {
        .btn-ticket {
            font-size: 7px;
            font-weight: bold;
            width: 69px;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                padding: 2px 0;
                width: 180px;
            }
        }

        .phone {
            color: inherit;
            display: none;
            font-size: 20px;
            font-weight: 700;
            margin-left: 40px;

            @include media-breakpoint-up(lg) {
                display: inline-block;
            }
        }
    }

    .mod-languages {
        text-align: right;
        margin-right: 16px;

        @include media-breakpoint-up(lg) {
            margin-right: 30px;
            max-width: $decor-column-max-width-lg;
            width: 100%;
        }

        a {
            font-size: 14px;
            line-height: 2;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                line-height: normal;
            }
        }

        .dropdown-toggle {
            border: 0;
            color: $color-inverse;
            text-transform: uppercase;
            padding-top: 0;
            padding-bottom: 0;
            box-shadow: none !important;

            &::after {
                //background: url("../images/icon-down.png") center no-repeat;
                //background-size: cover;
                content: '';
                height: 5px;
                width: 9px;
                vertical-align: .1em;
                border-top: none;
                border-right: none;
                border-bottom: none;
                border-left: none;

                @include media-breakpoint-up(lg) {
                    height: 8px;
                    width: 14px;
                }
            }
        }

        ul.lang-inline {
            border: 0;
            min-width: 0;

            li {
                display: block;
                margin-left: .75rem;
                margin-right: .75em;
            }
            a {
                color: $color-main;
                font-weight: 400;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &.scroll {
            .navbar-brand {
                display: none !important;
            }
            .navbar-ticket {
                display: block !important;
            }
        }
    }
}

#nav-modules {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-main;
    z-index: 200;

    @include media-breakpoint-up(lg) {
        background-image: none !important;
        bottom: auto;
        top: 80px;
    }

    .close {
        background: url("../images/ico-close.png") center no-repeat;
        background-size: 20px;
        height: 30px;
        width: 30px;
        opacity: 1;
        top: 5px;
        right: 5px;
        position: absolute;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    ul.nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        li {
            margin: 10px 0;
            @include media-breakpoint-up(lg) {
                margin: 0 10px;
            }
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        a {
            color: $color-inverse;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;

            &:hover {
                color: $color-accent;
            }

            @include media-breakpoint-up(lg) {
                line-height: 60px;
            }
        }
    }

    &.collapsing {
        ul.nav {
            display: none;
        }
    }
}