.callback-module {
    position: fixed;
    right: 20px;
    bottom: 50px;
    max-width: 50px;
    max-height: 50px;
    overflow: hidden;
    transition: all ease-in-out 200ms;
    z-index: 5;

    @include media-breakpoint-up(lg) {
        right: 50px;
        bottom: 50px;
        max-width: 70px;
        max-height: 70px;
    }

    &.active {
        max-width: 400px;
        max-height: 400px;

        .trigger {
            background-image: url("../images/ico-close.svg");
        }

        .callback-module-ph {
            max-height: 350px;
        }

        .label {
            padding: 0 20px;
            max-width: 350px;
        }
    }

    &.hovered {
        max-width: 400px;

        .label {
            padding: 0 20px;
            max-width: 350px;
        }
    }

    .callback-module-title {
        display: flex;
        justify-content: flex-end;
    }

    .label {
        background: $color-main;
        color: $color-inverse;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        overflow: hidden;
        max-width: 0;
        white-space: nowrap;

        header {
            font-weight: bold;
        }

        p {
            margin: 0;
        }
    }
    .trigger {
        background: $color-accent url("../images/ico-call.svg") center no-repeat;
        background-size: 70%;
        flex-shrink: 0;
        display: block;
        height: 50px;
        width: 50px;

        @include media-breakpoint-up(lg) {
            height: 70px;
            width: 70px;
        }
    }

    .callback-module-ph {
        max-height: 0;
        overflow: hidden;
    }
    .callback-form {
        background: #fff;
        padding: 10px 20px 20px;

        form {
            display: flex;
            align-items: flex-end;
            position: relative;
        }

        .field {
            margin: 0 !important;
        }

        .prompt.label {
            position: absolute;
            left: 10px;
            margin-top: 45px;
        }
        input {
            border: $color-accent 1px solid !important;
            border-radius: 0 !important;
            max-width: 170px;
        }
        button {
            background: $color-main !important;
            border-radius: 0 !important;
            margin-right: 0;
            padding: 12px 21px;
        }
    }

    .callback-socials {
        background: $color-accent;
        display: flex;
        padding: 0 10px;

        .callback-social {
            display: block;
            height: 50px;
            width: 50px;
            padding: 10px;

            img {
                display: block;
                height: 100%;
                width: 100%;
            }
        }
    }
}