.sppb-section {
    &.section-tickets {
        @include sectionHeading($color-main);
        @include sectionHeadingText('Tickets');

        & > .sppb-container-inner {
            @extend .ph;
        }

        @include media-breakpoint-up(lg) {
            @include sectionHeading($color-main);
        }

        .sppb-column-addons {
            & > div {
                margin: 0 !important;
            }
            & > .sppb-section {
                overflow: visible;

                & > .sppb-container-inner {
                    overflow: visible;
                }
            }
            .sppb-column-addons {
                @include media-breakpoint-up(lg) {
                    display: flex;
                    justify-content: space-around;

                    & > div {
                        & > div {
                            height: 100%;
                        }
                    }
                }
            }
        }

        .phones {
            display: flex;
            font-size: 20px;
            flex-direction: column;

            .phone {
                color: $color-main;
                padding: 10px 0;
                text-align: center;
            }
            @include media-breakpoint-up(lg) {
                flex-direction: row;
                justify-content: center;
                font-size: 24px;

                .phone {
                    padding: 0 25px;
                }
            }
        }

        .sppb-addon-ticket {
            background: $color-inverse;
            box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.3);
            border-radius: 20px;
            color: $color-main;
            font-size: 11px;
            font-weight: 300;
            margin: 35px auto;
            max-width: 210px;
            position: relative;

            @include media-breakpoint-up(lg) {
                border-radius: 70px;
                font-size: 16px;
                max-width: 100%;
                width: 315px;
                height: 100%;
            }

            .sppb-addon-content {
                display: flex;
                flex-direction: column;
                min-height: 310px;

                @include media-breakpoint-up(lg) {
                    min-height: 500px;
                    height: 100%;
                }
            }

            .sppb-ticket-title {
                background: $color-accent;
                border-radius: 20px 20px 0 0;
                font-size: 2.5rem;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                padding: 20px;
                margin-bottom: 20px;

                @include media-breakpoint-up(lg) {
                    font-size: 3rem;

                    border-radius: 70px 70px 0 0;
                    padding-top: 2.5rem;
                    padding-bottom: 2.5rem;
                    margin-bottom: 2.5rem;
                }
            }

            .sppb-ticket-buy {
                background: $color-main;
                border-radius: 360px;
                color: $color-inverse;
                cursor: pointer;
                display: block;
                font-weight: bold;
                margin: 0 auto 20px;
                padding: 5px 20px;
                text-align: center;
                text-transform: uppercase;
                width: 85px;

                @include media-breakpoint-up(lg) {
                    width: 140px;
                }
            }

            .sppb-ticket-feature {
                font-weight: 500;
                padding: 0 20px;
                position: relative;
                margin: 0;

                @include media-breakpoint-up(lg) {
                    padding: 0 2.5rem;
                }

                &::before {
                    display: block;
                    content: '-';
                    font-size: 14px;
                    position: absolute;
                    left: 1rem;
                    top: -2px;

                    @include media-breakpoint-up(lg) {
                        font-size: 20px;
                        left: 1.5rem;
                        top: -4px;
                    }
                }

                b, strong {
                    font-weight: bold;
                }

                &.plus {
                    position: relative;

                    &::before {
                        display: block;
                        content: '+';
                        font-size: 14px;
                        position: absolute;
                        left: -12px;
                        top: -4px;

                        @include media-breakpoint-up(lg) {
                            font-size: 20px;
                            left: -16px;
                        }
                    }
                }
            }

            .sppb-ticket-price {
                text-align: center;
                margin: auto auto 10px;
                font-weight: 900;
                padding: 10px 30px 0;
            }

            &.ticket-premium {
                .sppb-ticket-title {
                    background: $color-main;
                    color: $color-inverse;
                }
            }

            &.ticket-vip {
                .sppb-ticket-title {
                    background: #ab8a54;
                    color: $color-inverse;
                }
            }
        }

        .ticket-comment {
            margin: 0 20px;

            &::before {
                display: block;
                content: '*';
                margin-left: -6px;
                position: absolute;
            }

            @include media-breakpoint-up(lg) {
                margin: 70px 40px 0;

                &::before {
                    margin-left: -10px;
                }
            }

            @include media-breakpoint-down(lg) {
                span {
                    font-size: 20px !important;
                }
            }
        }
    }
}

.ui.fullscreen.modal {
    border: 1px solid $color-main;
    border-radius: 0;
    background-color: $color-inverse;
    margin: 0 21px;
    padding: 20px 25px;
    position: relative;

    .close.icon {
        background: url('../images/icon-close-main.png') center no-repeat;
        background-size: 18px;
        height: 36px;
        position: absolute;
        width: 36px;

        right: 9px;
        top: 9px;
        z-index: 110;

        &::before {
            display: none !important;
        }

        @include media-breakpoint-up(lg) {
            background-size: 35px;
            right: 44px;
            top: 44px;
        }
    }

    @include media-breakpoint-up(lg) {
        border: 3px solid $color-main;
        max-width: 925px;
        margin: 0 auto;
        padding: 70px 35px;
    }
    iframe {
        width: 100%;
    }
}

.ticket-form {
    color: $color-main;
    text-align: center;
    overflow: hidden;

    h2 {
        border-bottom: $color-main 1px solid;
        display: inline-block;
        font-weight: 700;
        padding-bottom: 20px;
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            font-size: 18px !important;
            margin: auto 45px 20px !important;
        }

        @include media-breakpoint-up(md) {
            font-size: 26px !important;
            margin: auto auto 20px !important;
            padding-bottom: 10px;
        }
    }
    h3 {
        font-size: 18px !important;
        margin: 0 auto 40px !important;
        max-width: 500px;

        @include media-breakpoint-down(md) {
            font-size: 12px !important;
        }
        @include media-breakpoint-up(md) {
            display: block;
            font-size: 14px !important;
        }
    }
    .fields {
        flex-wrap: wrap;
        text-align: left;

        .field {
            position: relative;

            input[type=text] {
                border: 1px solid $color-main;
                border-radius: 0 !important;
                background-color: #efeff1;
            }
            label {
                color: $color-main !important;
                @include media-breakpoint-down(md) {
                    font-size: 18px !important;
                }

                &::after {
                    color: $color-main !important;
                }
            }
            .prompt.label {
                position: absolute;
                left: 6px;
                top: 70px;
                z-index: 1;
            }
        }

        @include media-breakpoint-up(md) {
            margin: 0 45px !important;
            padding-left: 55px !important;
            padding-right: 55px !important;

            .field {
                margin-bottom: 20px !important;

                label {
                    font-size: 16px !important;
                }
            }
        }
    }

    h2.payment {
        border-bottom: 0;
        font-weight: 900 !important;
        display: block;
        margin-left: 10px !important;
        margin-right: 10px !important;
        margin-bottom: 0 !important;

        @include media-breakpoint-down(md) {
            font-size: 14px !important;
            text-align: left;
        }
        @include media-breakpoint-up(md) {
            font-size: 16px !important;
            text-align: center;
        }
    }
    .multifield.payment {
        @include media-breakpoint-down(md) {
            margin: 10px !important;
        }

        @include media-breakpoint-up(md) {
            margin: 0 130px !important;

            .field {
                margin-bottom: 10px !important;
            }
        }
    }

    .button-send {
        text-transform: uppercase !important;
        .button.green {
            background-color: $color-main !important;
            border-radius: 360px;
        }

        @include media-breakpoint-up(md) {
            margin: 20px 100px;
        }
    }

    .comment {
        font-size: 9px;
        font-weight: 400;
        text-align: left;
        margin-top: 5px;

        @include media-breakpoint-up(md) {
            margin: 10px 100px 5px;
            font-size: 14px;
        }
    }

    .comment {
        font-weight: 400;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 30px;

        a {
            white-space: nowrap;
        }
    }
}

.ui.message.success {
    background: none !important;
    box-shadow: none !important;
    border: 0;
    border-radius: 0 !important;
    color: $color-main !important;
    font-size: 18px;
    font-weight: 400;
    text-align: center !important;
    margin: auto !important;

    @include media-breakpoint-up(lg) {
        font-size: 30px;
        max-width: 620px !important;
    }
}