.sppb-section {
    &.section-contacts {
        @include sectionHeading($color-main);
        @include sectionHeadingText('Contacts', $color-inverse);

        & > .sppb-container-inner {
            @extend .ph;
        }

        .sppb-column-addons {
            & > div {
                margin-bottom: 0 !important;
            }
        }

        color: $color-inverse;
        font-size: 14px;
        padding-bottom: 35px;

        @include media-breakpoint-up(lg) {
            @include sectionHeading($color-main);

            font-size: 18px;
            padding-top: 100px;
            padding-bottom: 55px;
        }

        a {
            color: $color-inverse;

            &:hover {
                color: $color-accent !important;
                text-decoration: none;
            }
        }

        .event {
            .heading {
                color: $color-accent;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;

                @include media-breakpoint-up(lg) {
                    font-size: 30px;
                }
            }
            .date {
                font-weight: 700;

                @include media-breakpoint-up(lg) {
                    font-size: 26px;
                }
            }
            .city {
                text-transform: uppercase;

                @include media-breakpoint-up(lg) {
                    font-size: 26px;
                }
            }
        }

        .person {
            margin-bottom: 50px;

            .image {
                background: rgba(253, 224, 58, .7);
                border-radius: 100%;
                float: left;
                display: inline-block;
                padding: 8px;
                position: relative;
                width: 137px;

                img {
                    border-radius: 100%;
                    display: block;
                    width: 100%;
                }

                @media (max-width: 320px) {
                    width: 100px;
                }

                @include media-breakpoint-up(lg) {
                    width: 236px;
                }
            }
            .name {
                text-transform: uppercase;
                font-weight: 700;
            }
            .position {
                margin: 2px 0 10px;
            }

            .phone, .mail {
                color: $color-inverse;
                display: block;
                font-size: 10px;

                @include media-breakpoint-up(lg) {
                    font-size: 14px;
                }
            }
        }

        .menu-bottom {
            margin-bottom: 30px;
            & > .sppb-container-inner {
                & > .sppb-row {
                    & > .sppb-col-md-12 {
                        & > .sppb-column {
                            & > .sppb-column-addons {
                                display: flex;
                                align-items: flex-end;

                                & > div {
                                    width: 50%;
                                    position: relative;
                                }

                                @include media-breakpoint-up(lg) {
                                    align-items: flex-start;
                                    & > div {
                                        width: auto;

                                        &:first-child {
                                            min-width: 150px;
                                            padding-top: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .socials {
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    @media (max-width: 320px) {
                        margin-right: 5px;
                    }

                    a {
                        display: block;
                        background-size: cover !important;
                        height: 35px;
                        width: 35px;

                        @media (max-width: 320px) {
                            height: 30px;
                            width: 30px;
                        }
                    }

                    .in {
                        background: url("../images/ico-in.png") top no-repeat;
                        &:hover {
                            background-position: bottom;
                        }
                    }
                    .fb {
                        background: url("../images/ico-fb.png") top no-repeat;
                        &:hover {
                            background-position: bottom;
                        }
                    }
                    .yt {
                        background: url("../images/ico-yt.png") top no-repeat;
                        &:hover {
                            background-position: bottom;
                        }
                    }
                }
            }
            .sppb-addon-module.menu {
                text-align: right;

                ul {
                    display: block !important;
                    margin-bottom: 0;

                    li {
                        display: block;
                        margin: 6px 0;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    font-size: 16px;
                    ul {
                        display: flex !important;
                        justify-content: center;

                        li {
                            margin: 0 15px;
                            line-height: 40px;
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
            .contact-1, .contact-3 {
                overflow: hidden;

                .image {
                    float: right;
                }
                .info {
                    position: absolute;
                    margin-top: 50px;
                }
            }
            .contact-2 {
                overflow: hidden;

                .image {
                    float: left;
                }

                .info {
                    text-align: right;
                    margin-top: 50px;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .event {
                float: left;
                width: 50%;
            }
            .contact-1 {
                overflow: hidden;

                .image {
                    float: left;
                    margin-right: 20px;
                }
                .info {
                    position: static;
                    margin-top: 25px;
                }
            }
            .contact-2 {
                float: left;
                padding-right: 20px;
                width: 50%;

                .image {
                    float: left;
                }
                .info {
                    text-align: right;
                    margin-top: 124px;
                    position: static;
                }
            }
            .contact-3 {
                float: right;
                padding-left: 20px;
                width: 50%;

                .image {
                    float: right;
                }
                .info {
                    margin-top: 125px;
                    position: static;
                }
            }
        }

        @media (max-width: 320px) {
            .contact-2 {
                padding-top: 50px;

                .info {
                    margin-top: 25px;
                }
            }
        }
    }
}