.sppb-section {
    &.section-conference2020 {
        @include sectionHeading($color-main, $color-main, url("../images/dots-a-42.png") 10px 30% no-repeat);

        @include media-breakpoint-up(lg) {
            @include sectionHeading($color-main, $color-main, url("../images/dots-a-110.png") -50px 70% no-repeat);
            padding-top: 0;
        }

        @include media-breakpoint-down(lg) {
            .sppb-addon-text-block {
                padding: 20px;
            }
        }
    }
}