.sppb-section {
    &.section-main {
        @include sectionHeading($color-main);
        @include sectionHeadingText('Main info', $color-inverse);

        text-align: left;

        .sppb-container-inner {
            @extend .ph;
        }

        .icon-row {
            padding-top: 0 !important;
        }

        .sppb-conf-icon {
            display: flex;
            align-items: center;
            margin: 20px 0;

            .sppb-conf-icon-image {
                overflow: hidden;
                position: relative;
                height: 85px;
                width: 85px;
                margin-right: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                .hover {
                    position: absolute;
                    visibility: hidden;
                }
                
                img {
                    display: block;
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                }
            }

            &.mic {
                .sppb-conf-icon-image {
                    img {
                        height: 70%;
                        width: 70%;
                    }
                }
            }

            &:hover {
                .sppb-conf-icon-image {
                    .static {
                        visibility: hidden;
                    }
                    .hover {
                        visibility: visible;
                    }
                }
            }

            .description {
                font-weight: 400;

                span {
                    display: block;
                    font-size: 1.9rem;
                    font-weight: bold;
                    margin-bottom: 1rem;
                }
            }
        }

        .sppb-addon-video {
            border: $color-accent 3px solid;
            overflow: hidden;
        }

        .sppb-addon-text-block {
            font-weight: 400;
            text-align: left;

            h2 {
                font-size: 1.8rem;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 1.5rem;

                span {
                    background: $color-accent;
                    color: $color-main;
                    padding: 0 .5rem;
                }
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                margin-left: .5rem;
                padding: 0;
                list-style: none;

                li {
                    margin: .3em 0;
                }
            }
        }
    }

    &.section-main {
        .pain {
            & > .sppb-container-inner {
                @extend .ph;
            }
            width: auto;

            .sppb-column-addons {
                align-self: flex-end;
            }

            ul {
                list-style-type: none;
                margin: 0;
                position: relative;
                z-index: 1;
            }

            li {
                border-bottom: 0 !important;
                line-height: 1.5 !important;
                margin: 10px 0;
                position: relative;
                z-index: 2;
            }

            strong {
                display: block;
                text-transform: uppercase;
                font-size: 1.4rem;
            }

            .subtitle {
                margin: .4rem 0 .4rem 2rem;
                position: relative;

                &:before {
                    background: $color-accent;
                    border-radius: 100%;
                    content: '';
                    display: block;
                    position: absolute;
                    top: .35rem;
                    left: -1.2rem;
                    height: .7rem;
                    width: .7rem;
                }
            }
        }
    }
}