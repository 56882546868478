.sppb-section {
    &.section-thanks {
        .sppb-column-addons {
            & > div {
                margin-bottom: 0 !important;
            }
        }
        & > .sppb-container-inner {
            padding-left: 0 !important;
        }

        color: $color-inverse;
        font-size: 16px;
        height: 100vh;
        text-align: center;

        .sppb-addon-text-block {
            &:before {
                background: url("../images/checked-w.svg") center no-repeat;
                background-size: contain;
                content: '';
                display: block;
                height: 100px;
                margin-bottom: 20px;
            }
        }

        iframe {
            margin-top: 40px;
        }
    }
}